import React, { useEffect, useState } from 'react'
import {
  CCol,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import AnalyticService from 'src/service/AnalyticService'

const GameAnalytic = () => {
  const [data, setData] = useState({})
  useEffect(() => {
    AnalyticService.getGameAnalyticData().then((res) => {
      if (res.status === 200) {
        setData(res.data)
      }
    })
  }, [])
  return (
    <CRow>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Game Analytics</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CRow>
              <CCol xs={12}>
                <table className="main-table table table-bordered">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>{data?.total_count}</td>
                    </tr>
                    <tr>
                      <td>Dashboard</td>
                      <td>{data?.dashboard_count}</td>
                    </tr>
                    <tr>
                      <td>My Team</td>
                      <td>{data?.my_team_count}</td>
                    </tr>
                    <tr>
                      <td>Rounds</td>
                      <td>{data?.round_count}</td>
                    </tr>
                    <tr>
                      <td>Rank</td>
                      <td>{data?.rank_count}</td>
                    </tr>
                    <tr>
                      <td>Players</td>
                      <td>{data?.player_count}</td>
                    </tr>
                    <tr>
                      <td>Stats</td>
                      <td>{data?.stats_count}</td>
                    </tr>
                    <tr>
                      <td>Fixtures</td>
                      <td>{data?.fixtures_count}</td>
                    </tr>
                    <tr>
                      <td>Sponsors</td>
                      <td>{data?.sponsors_count}</td>
                    </tr>
                    <tr>
                      <td>Notifications</td>
                      <td>{data?.notifications_count}</td>
                    </tr>
                    <tr>
                      <td>Game Members</td>
                      <td>{data?.game_member_count}</td>
                    </tr>
                    <tr>
                      <td>Trade History</td>
                      <td>{data?.trade_history_count}</td>
                    </tr>
                    <tr>
                      <td>Timeline</td>
                      <td>{data?.timeline_count}</td>
                    </tr>
                    <tr>
                      <td>Bonus Card</td>
                      <td>{data?.bonus_card_count}</td>
                    </tr>
                    <tr>
                      <td>TOTR</td>
                      <td>{data?.totr_count}</td>
                    </tr>
                    <tr>
                      <td>Bracket Battle</td>
                      <td>{data?.bracket_battle_count}</td>
                    </tr>
                    <tr>
                      <td>Head-2-Head</td>
                      <td>{data?.h2h_count}</td>
                    </tr>
                    <tr>
                      <td>Game Charts</td>
                      <td>{data?.game_chart_count}</td>
                    </tr>
                    <tr>
                      <td>Player Charts</td>
                      <td>{data?.player_chart_count}</td>
                    </tr>
                  </tbody>
                </table>
              </CCol>
            </CRow>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default GameAnalytic
