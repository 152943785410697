import { CSmartTable } from '@coreui/react-pro'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PlayerService from 'src/service/PlayerService'
import PlayerStatTable from './PlayerStatTable'

const PlayerStats = () => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [users, setUsers] = useState({})
  const columns = [
    { label: 'Player Name', filter: true, sorter: true, key: 'player_name' },
    { label: 'POS', filter: false, sorter: true, key: 'position_name' },
    { label: 'PT', filter: false, sorter: true, key: 'sum_pt' },
    { label: 'ST', filter: false, sorter: true, key: 'sum_st' },
    { label: 'REB', filter: false, sorter: true, key: 'sum_reb' },
    { label: 'AST', filter: false, sorter: true, key: 'sum_ast' },
    { label: 'BKS', filter: false, sorter: true, key: 'sum_bks' },
    { label: 'TOV', filter: false, sorter: true, key: 'sum_tov' },
    { label: '3P', filter: false, sorter: true, key: 'sum_threep' },
    { label: 'PFS', filter: false, sorter: true, key: 'sum_pfs' },
    { label: 'FP', filter: false, sorter: true, key: 'sum_fp' },
  ]

  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    PlayerService.playerStats(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }
  return (
    <>
      <PlayerStatTable
        users={users}
        activePage={activePage}
        setActivePage={setActivePage}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        columnSorter={columnSorter}
        setColumnSorter={setColumnSorter}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        columns={columns}
        fetchData={fetchData}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  )
}

export default PlayerStats
