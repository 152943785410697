import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CRow,
  CTableHeaderCell,
  CLoadingButton,
  CTableRow,
  CFormSelect,
  CFormLabel,
  CMultiSelect,
  CFormTextarea,
  CPopover,
  CButton,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CInputGroup,
  CInputGroupText,
  CHeaderNav,
  CButtonGroup,
  CTooltip,
  CLink,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import Bootbox from 'bootbox-react'
import * as Yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import ScorecardService from 'src/service/ScorecardService'
import Loader from 'src/components/Loader'
import { cilInfo, cilList, cilReload, cilSave } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import CompleteMarkModal from './CompleteMarkModal'
import MarkAsCompleteModal from './MarkAsCompleteModal'
import moment from 'moment'
import LegendInfo from './LegendInfo'

const ScoreCard = () => {
  const formRef = useRef(null)
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [completMarkModalVisible, setCompletMarkModalVisible] = useState(false)
  const [finalScorecardModalVisible, setFinalScorecardModalVisible] = useState(false)
  const [defaultInning, setDefaultInning] = useState(1)
  const [scorecardDetails, setScorecardDetails] = useState([])
  const [commonScorecardLoading, setCommonScorecardLoading] = useState(false)
  const [positionList, setPositionList] = useState()
  const [playerList, setPlayerList] = useState([])
  const [fixtureDetails, setFixtureDetails] = useState({})
  const [loader, setLoader] = useState(false)
  const [statusValue, setStatusValue] = useState()
  const param = useParams()

  const fetchData = () => {
    ScorecardService.scorecardDetail(param.fixtureId, defaultInning)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          setCommonScorecardLoading(false)
          setScorecardDetails(res.data)
          setPositionList(res.position_list)
          if (res.player_list.length < 5) {
            ToastComponent(
              'Please add a minimum of 5 players to the squad list of this fixture!',
              'error',
            )
            navigate(`/manage-scores/team-players/${param.fixtureId}`)
          }
          setPlayerList(res.player_list)
          setFixtureDetails(res.fixtureDetails)
          setStatusValue(res.fixtureDetails.status)
        }
      })
      .catch((e) => {
        ToastComponent(e.response?.data?.message, 'error')
        setLoader(false)
      })
  }
  useEffect(() => {
    setLoader(true)
    fetchData()
  }, [])
  const formik = useFormik({
    initialValues: {
      data: scorecardDetails,
      scorcard_link_name: fixtureDetails?.scorcard_link_name,
      scorcard_link_url: fixtureDetails?.scorcard_link_url,
      fall_of_wickets: fixtureDetails?.fall_of_wickets,
      match_report: fixtureDetails?.match_report,
      potm_match_status: fixtureDetails?.potm_match_status,
      match_status: '',
    },
    enableReinitialize: true,
    onSubmit: (data, actions) => {
      data.fixtureId = param.fixtureId
      data.potm = selectedValue
      data.status = statusValue
      setLoader(true)
      ScorecardService.saveScorecard(data)
        .then((res) => {
          if (res.status === 200) {
            setScorecardDetails([])
            fetchData(param.fixtureId, defaultInning)
            setLoader(true)
            ToastComponent(res.message, 'success')
          } else {
            setLoader(true)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          setLoader(true)
          ToastComponent(e.response?.data?.message, 'error')
        })
    },
  })
  const [selectedValue, setSelectedValue] = useState([])
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  }
  const [completeMarkModalData, setCompleteMarkModalData] = useState([])
  const handleMatchStatus = (e) => {
    setStatusValue(e.target.value)
    const data = {}
    data.fixtureId = param.fixtureId
    data.status = e.target.value
    if (e.target.value === '2') {
      ScorecardService.getSavedScorecardData(data).then((res) => {
        if (res.status === 200) {
          setCompleteMarkModalData(res.data)
          setCompletMarkModalVisible(true)
        }
      })

      // ScorecardService.markAsComplete(param.fixtureId)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       ToastComponent(res.message, 'success')
      //       navigate('/manage-scores')
      //     }
      //   })
      //   .catch((e) => {
      //     ToastComponent('Something went wrong.', 'error')
      //   })
    } else {
      formikRef.current.handleSubmit()
      ScorecardService.changFixtureStatus(param.fixtureId, e.target.value)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            navigate(`/scorecard/${param.fixtureId}`)
          } else {
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          console.log('e', e)
          ToastComponent('Something went wrong.', 'error')
        })
    }
  }

  const formikRef = React.useRef()
  useEffect(() => {
    formikRef.current = formik
  }, [formik])

  const changeInning = (e) => {
    setDefaultInning(e.target.value)
  }

  const [isMobile, setIsMobile] = useState(false)
  // Function to update the isMobile state based on the screen width
  const updateScreenSize = () => {
    setIsMobile(window.innerWidth <= 768) // Adjust the breakpoint as needed
  }
  // Use useEffect to update the screen size state on component mount and window resize
  useEffect(() => {
    updateScreenSize() // Initial check
    window.addEventListener('resize', updateScreenSize)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [])
  function formatFantasyPoints(fantasyPoints) {
    if (fantasyPoints) {
      // Check if there are decimal digits
      if (fantasyPoints % 1 !== 0) {
        return fantasyPoints.toFixed(2).replace(/0+$/, '') // Remove trailing zeros
      } else {
        return fantasyPoints.toString() // Convert to a string without decimal places
      }
    } else return 0
  }
  const [infoPageModal, setInfoPageModal] = useState(false)
  const handleInfoClick = () => {
    setInfoPageModal(true)
  }

  const handleInputChange = (event, type) => {
    formik.handleChange(event) // Update formik state
    const data = {}
    data.fixtureId = param.fixtureId
    data.type = type
    data.value = event.target.value
    ScorecardService.updateCustomScorecardField(data).then((res) => {
      if (res.status === 200) {
        setLoader(false)
        ToastComponent(res.message, 'success')
      }
    })
  }
  const handleOutsideButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit')) // Trigger form submission
    }
  }
  const [showResetConfirm, setShowResetConfirm] = useState(false)
  const handleResetConfirm = () => {
    resetFixtureScorecard()
    return setShowResetConfirm(false)
  }
  const handleResetCancel = () => {
    return setShowResetConfirm(false)
  }
  const clearScorecard = () => {
    setShowResetConfirm(true)
    console.log('clear scorecard')
  }
  const resetFixtureScorecard = () => {
    setCommonScorecardLoading(true)
    const data = {}
    data.fixtureId = param.fixtureId
    data.inning = defaultInning
    ScorecardService.clearFixtureScorecard(data).then((res) => {
      if (res.status === 200) {
        console.log('ASdasdasd')
        setScorecardDetails([])
        setCommonScorecardLoading(false)
        fetchData()
        ToastComponent(res.message, 'success')
      }
    })
  }
  return commonScorecardLoading ? (
    <Loader />
  ) : (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CRow>
          <CCol xs={12} md={3} className="pt-3 pb-2">
            <span>
              <b> Round</b>: {fixtureDetails.round}
            </span>
          </CCol>
          <CCol xs={12} md={3} className="pt-3 pb-2">
            <span>
              <b> Team</b>: {fixtureDetails.team_name}
            </span>
          </CCol>
          <CCol xs={12} md={3} className="pt-3 pb-2">
            <span>
              {' '}
              <b> Start Date/Time</b>: {moment(fixtureDetails.start_date).format('D.MM.YYYY')}
              {', '}
              {moment(fixtureDetails.start_time, ['HH:mm']).format('h:mma')}
            </span>
          </CCol>
          <CCol xs={12} md={3} className="pt-3 pb-2">
            <span>
              <b> End Date/Time</b>: {moment(fixtureDetails.end_date).format('D.MM.YYYY')}
              {', '}
              {moment(fixtureDetails.end_time, ['HH:mm']).format('h:mma')}
            </span>
          </CCol>

          {isMobile ? (
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>
                    <strong>
                      {fixtureDetails.scorcard_link_url ? (
                        <Link to={fixtureDetails.scorcard_link_url} target="_blank">
                          Scorecard
                        </Link>
                      ) : (
                        'Scorecard'
                      )}
                    </strong>
                  </strong>
                  <CHeaderNav className="float-end">
                    <CButtonGroup aria-label="Theme switch">
                      <CIcon icon={cilInfo} />
                    </CButtonGroup>
                  </CHeaderNav>
                </CCardHeader>
                <CCardBody>
                  {loader ? (
                    <Loader />
                  ) : (
                    <CAccordion alwaysOpen>
                      {scorecardDetails &&
                        scorecardDetails.map((item, key) => (
                          <CAccordionItem itemKey={key} key={key}>
                            <CAccordionHeader>{item.player_name}</CAccordionHeader>
                            <CAccordionBody>
                              <CRow className="mb-2">
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Point
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]pt`}
                                      type={'number'}
                                      defaultValue={item.pt}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="pt"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Steals
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]st`}
                                      type={'number'}
                                      defaultValue={item.st}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="st"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Rebounds
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]reb`}
                                      type={'number'}
                                      defaultValue={item.reb}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="reb"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Assists
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]ast`}
                                      type={'number'}
                                      defaultValue={item.ast}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="ast"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Blocks
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]bks`}
                                      type={'number'}
                                      defaultValue={item.bks}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="bks"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Turnover
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]tov`}
                                      type={'number'}
                                      defaultValue={item.tov}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="tov"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      3-Pointers
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]threep`}
                                      type={'number'}
                                      defaultValue={`item.threep`}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="threep"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Personal Fouls
                                    </CInputGroupText>
                                    <CFormInput
                                      name={`data[${key}]pfs`}
                                      type={'number'}
                                      defaultValue={item.pfs}
                                      onChange={formik.handleChange}
                                      className="col-sm-10"
                                      id="pfs"
                                    />
                                  </CInputGroup>
                                </div>
                                <div className="col-md-2 col-sm">
                                  <CInputGroup className="mb-2">
                                    <CInputGroupText
                                      id="inputGroup-sizing-default"
                                      style={{ width: '135px' }}
                                    >
                                      Fantasy Points
                                    </CInputGroupText>
                                    <CFormInput
                                      defaultValue={formatFantasyPoints(item.fantasy_points)}
                                      id="fantasy_points"
                                      disabled={true}
                                    />
                                  </CInputGroup>
                                </div>
                              </CRow>
                            </CAccordionBody>
                          </CAccordionItem>
                        ))}
                    </CAccordion>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          ) : (
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>
                    {fixtureDetails.scorcard_link_url ? (
                      <Link to={fixtureDetails.scorcard_link_url} target="_blank">
                        Scorecard
                      </Link>
                    ) : (
                      'Scorecard'
                    )}
                  </strong>
                  <CHeaderNav className="float-end">
                    <CButtonGroup aria-label="Theme switch">
                      <CButton color={'primary'} onClick={handleOutsideButtonClick} title="Save">
                        <CIcon icon={cilSave} />
                      </CButton>
                      <CButton
                        color={'danger'}
                        onClick={() => {
                          clearScorecard()
                        }}
                        title="Reset"
                      >
                        <CIcon icon={cilReload} />
                      </CButton>
                      <CButton
                        color={'success'}
                        href={`/manage-scores/team-players/${param.fixtureId}`}
                        title="Squad"
                      >
                        <CIcon icon={cilList} />
                      </CButton>
                      <CButton color={'warning'} onClick={handleInfoClick} title="Legend">
                        <CIcon icon={cilInfo} />
                      </CButton>
                    </CButtonGroup>
                  </CHeaderNav>
                </CCardHeader>
                <CCardBody>
                  {loader ? (
                    <Loader />
                  ) : (
                    <div className="table-responsive">
                      <table className="main-table table innertable">
                        <thead>
                          <tr>
                            <th>PLAYER</th>
                            <th>
                              <CTooltip content="Position">
                                <CLink> POS </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Position">
                                <CLink> FP </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Points">
                                <CLink> PT </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Steals">
                                <CLink> ST </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Rebounds">
                                <CLink> REB </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Assists">
                                <CLink> AST </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Blocks">
                                <CLink> BKS </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Turnover">
                                <CLink> TOV </CLink>
                              </CTooltip>{' '}
                            </th>
                            <th>
                              <CTooltip content="3-Pointers">
                                <CLink> 3P </CLink>
                              </CTooltip>
                            </th>
                            <th>
                              <CTooltip content="Personal Fouls">
                                <CLink> PFS </CLink>
                              </CTooltip>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {scorecardDetails &&
                            scorecardDetails.map((item, key) => (
                              <tr key={key}>
                                <td
                                  style={{
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 1,
                                    backgroundColor: '#f9f9f9',
                                    width: '35px',
                                  }}
                                >
                                  {item.player_name}
                                </td>
                                <td>
                                  {item?.player_position == 1 ? 'PG' : ''}
                                  {item?.player_position == 2 ? 'SG' : ''}
                                  {item?.player_position == 3 ? 'FWD' : ' '}
                                  {item?.player_position == 4 ? 'CEN' : ''}
                                </td>
                                <td>
                                  <CFormInput
                                    defaultValue={formatFantasyPoints(item.fantasy_points)}
                                    id="fantasy_points"
                                    disabled={true}
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]pt`}
                                    type={'number'}
                                    defaultValue={item.pt}
                                    onChange={formik.handleChange}
                                    id="pt"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]st`}
                                    type={'number'}
                                    defaultValue={item.st}
                                    onChange={formik.handleChange}
                                    id="st"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]reb`}
                                    type={'number'}
                                    defaultValue={item.reb}
                                    onChange={formik.handleChange}
                                    id="reb"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]ast`}
                                    type={'number'}
                                    defaultValue={item.ast}
                                    onChange={formik.handleChange}
                                    id="ast"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]bks`}
                                    type={'number'}
                                    defaultValue={item.bks}
                                    onChange={formik.handleChange}
                                    id="bks"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]tov`}
                                    type={'number'}
                                    defaultValue={item.tov}
                                    onChange={formik.handleChange}
                                    id="tov"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]threep`}
                                    type={'number'}
                                    defaultValue={item.threep}
                                    onChange={formik.handleChange}
                                    id="threep"
                                  />
                                </td>
                                <td>
                                  <CFormInput
                                    name={`data[${key}]pfs`}
                                    type={'number'}
                                    defaultValue={item.pfs}
                                    onChange={formik.handleChange}
                                    id="pfs"
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          )}
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Scorecard Details</strong>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md={6}>
                    <CFormLabel htmlFor="phone">Scorecard Source</CFormLabel>
                    <input
                      type="text"
                      name="scorcard_link_name"
                      className={'form-control'}
                      id="scorcard_link_name"
                      placeholder="Scorecard Source"
                      defaultValue={formik.values.scorcard_link_name}
                      onChange={(e) => handleInputChange(e, 'scorcard_link_name')}
                    />
                  </CCol>
                  <CCol md={6}>
                    <CFormLabel htmlFor="phone">Scorecard Link</CFormLabel>
                    <input
                      type="text"
                      name="scorcard_link_url"
                      className={'form-control'}
                      id="scorcard_link_url"
                      placeholder="Scorecard Link"
                      defaultValue={formik.values.scorcard_link_url}
                      onChange={(e) => handleInputChange(e, 'scorcard_link_url')}
                    />
                  </CCol>

                  {/* <CCol md={6}>
                    <CFormLabel htmlFor="fall_of_wickets" className="mt-3">
                      Fall of Wickets
                    </CFormLabel>
                    <CFormTextarea
                      aria-label="With textarea"
                      defaultValue={formik.values.fall_of_wickets}
                      name={'fall_of_wickets'}
                      onChange={(e) => handleInputChange(e, 'fall_of_wickets')}
                    ></CFormTextarea>
                  </CCol> */}
                  {/* <CCol md={6}>
                    <CFormLabel htmlFor="Add Match Report" className="mt-3">
                      Add Match Report
                    </CFormLabel>
                    <CFormTextarea
                      aria-label="With textarea"
                      defaultValue={formik.values.match_report}
                      name={'match_report'}
                      onChange={formik.handleChange}
                    ></CFormTextarea>
                  </CCol> */}
                  {/* <CCol md={6} className="mb-4">
                    <CFormLabel htmlFor="formFile" className="mt-3">
                      Upload Match Scorecard
                    </CFormLabel>
                    <CFormInput type="file" id="upload_video" />
                  </CCol> */}
                </CRow>
              </CCardBody>
            </CCard>
            <CompleteMarkModal visible={visible} setVisible={setVisible} />

            {/* <MarkAsCompleteModal
              completMarkModalVisible={completMarkModalVisible}
              setCompletMarkModalVisible={setCompletMarkModalVisible}
              data={completeMarkModalData}
            /> */}
          </CCol>
          <CCol xs={12}>
            <CCard className="mb-6">
              <CCardHeader>
                <strong>Bonus Points</strong>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md={6}>
                    <CFormLabel htmlFor="player_of_the_match">Player Of The Match</CFormLabel>

                    <CMultiSelect
                      id="player_of_the_match"
                      options={playerList}
                      selectionType="tags"
                      name="player"
                      onChange={handleChange}
                      value={playerList.filter((obj) => selectedValue.includes(obj.value))}
                    />
                  </CCol>
                  <CCol md={6}>
                    <CFormLabel htmlFor="Match Result">Match Result</CFormLabel>
                    <CFormSelect
                      id="potm_match_status"
                      value={formik.values.potm_match_status}
                      onChange={formik.handleChange}
                    >
                      <option>Select Match Result</option>
                      <option value="1">Won</option>
                      <option value="2">Other</option>
                    </CFormSelect>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CompleteMarkModal visible={visible} setVisible={setVisible} />

            {/* <MarkAsCompleteModalp
              completMarkModalVisible={completMarkModalVisible}
              setCompletMarkModalVisible={setCompletMarkModalVisible}
              data={completeMarkModalData}
            /> */}
          </CCol>

          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Save Scores</strong>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md={6}>
                    <CLoadingButton
                      type="submit"
                      color="success"
                      variant="outline"
                      loading={loader}
                      className="mt-3"
                    >
                      Press to Save
                    </CLoadingButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol xs={12}>
            <CCard className="mb-4 mt-3">
              <CCardHeader>
                <strong>Match Status</strong>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md={6}>
                    {' '}
                    <CFormLabel htmlFor="Add Match Report" className="mr-1 mt-3">
                      Match Status
                    </CFormLabel>
                    <CButton color="danger" size="sm" onClick={() => setVisible(!visible)}>
                      <CIcon icon={cilInfo} className="" fontSize={12} />
                    </CButton>
                    <CFormSelect
                      aria-label="Select Structure"
                      name="match_status"
                      className={
                        'mt-3 form-control' +
                        (formik.errors.match_status && formik.touched.match_status
                          ? ' is-invalid'
                          : '')
                      }
                      value={statusValue}
                      id="match_status"
                      onChange={handleMatchStatus}
                    >
                      <option value={0}>Select Match Status </option>
                      <option value={1}>In-Progress</option>
                      <option value={2}>Completed</option>
                    </CFormSelect>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CompleteMarkModal visible={visible} setVisible={setVisible} />

            <MarkAsCompleteModal
              completMarkModalVisible={completMarkModalVisible}
              setCompletMarkModalVisible={setCompletMarkModalVisible}
              data={completeMarkModalData}
              fixtureId={param.fixtureId}
              finalScorecardModalVisible={finalScorecardModalVisible}
              setFinalScorecardModalVisible={setFinalScorecardModalVisible}
            />
          </CCol>
        </CRow>
      </CForm>
      <LegendInfo infoPageModal={infoPageModal} setInfoPageModal={setInfoPageModal} />
      <Bootbox
        show={showResetConfirm}
        type={'confirm'}
        message={'Are you sure you want to reset this?'}
        onSuccess={handleResetConfirm}
        onCancel={handleResetCancel}
        onClose={handleResetCancel}
      />
    </>
  )
}

export default ScoreCard
