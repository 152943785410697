import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CRow,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CTableDataCell,
} from '@coreui/react-pro'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
import ScorecardService from 'src/service/ScorecardService'
import Loader from 'src/components/Loader'

const ShowScorecard = () => {
  const [scorecardDetails, setScorecardDetails] = useState()
  const [fixtureDetails, setFixtureDetails] = useState({})
  const [loader, setLoader] = useState(false)
  const param = useParams()
  useEffect(() => {
    setLoader(true)
    ScorecardService.showScorecard(param.fixtureId)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          setScorecardDetails(res.data)
          setFixtureDetails(res.fixture_data)
        }
      })
      .catch((e) => {
        ToastComponent('Something went wrong. Please try again.', 'error')
        setLoader(false)
      })
  }, [param.fixtureId])
  return (
    <>
      <CRow>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            <b> Round</b>: {fixtureDetails.round}
          </span>
        </CCol>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            <b> Team</b>: {fixtureDetails.team_name}
          </span>
        </CCol>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            {' '}
            <b> Start Date/Time</b>: {moment(fixtureDetails.start_date).format('D.MM.YYYY')}
            {', '}
            {moment(fixtureDetails.start_time, ['HH:mm']).format('h:mma')}
          </span>
        </CCol>
        <CCol xs={12} md={3} className="pt-3 pb-2">
          <span>
            <b> End Date/Time</b>: {moment(fixtureDetails.end_date).format('D.MM.YYYY')}
            {', '}
            {moment(fixtureDetails.end_time, ['HH:mm']).format('h:mma')}
          </span>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Scorecard</strong>
            </CCardHeader>
            <CCardBody>
              {loader ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <table className="main-table table innertable">
                    <thead>
                      <tr>
                        <th>PLAYER</th>
                        <th>PT</th>
                        <th>ST</th>
                        <th>REB</th>
                        <th>AST</th>
                        <th>BKS</th>
                        <th>TOV </th>
                        <th>3P</th>
                        <th>PFS</th>
                        <th>FP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scorecardDetails &&
                        scorecardDetails.map((item, key) => (
                          <CTableRow key={key}>
                            <CTableHeaderCell>{item.player_name}</CTableHeaderCell>
                            <CTableHeaderCell>{item.pt}</CTableHeaderCell>
                            <CTableHeaderCell>{item.st}</CTableHeaderCell>
                            <CTableHeaderCell>{item.reb}</CTableHeaderCell>
                            <CTableHeaderCell>{item.ast}</CTableHeaderCell>
                            <CTableHeaderCell>{item.bks}</CTableHeaderCell>
                            <CTableHeaderCell>{item.tov}</CTableHeaderCell>
                            <CTableHeaderCell>{item.threep}</CTableHeaderCell>
                            <CTableHeaderCell>{item.pfs}</CTableHeaderCell>
                            <CTableHeaderCell>{item.fantasy_points}</CTableHeaderCell>
                          </CTableRow>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Scorecard Details</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                {/* <CCol md={4} className="mb-4">
                  <CFormLabel htmlFor="formFile">Upload Match Scorecard</CFormLabel>
                  <CFormInput type="file" id="upload_video" />
                </CCol> */}
                {fixtureDetails.scorcard_link_name !== 0 && (
                  <CCol md={4}>
                    <CFormLabel htmlFor="phone">Scorecard Source: &nbsp;</CFormLabel>
                    {fixtureDetails?.scorcard_link_name}
                  </CCol>
                )}
                {fixtureDetails?.scorcard_link_url !== 0 && (
                  <CCol md={4}>
                    <CFormLabel htmlFor="phone">Scorecard Link: &nbsp;</CFormLabel>
                    {fixtureDetails?.scorcard_link_url}
                  </CCol>
                )}
                {fixtureDetails?.potm_match_status !== 0 && (
                  <CCol md={4}>
                    <CFormLabel htmlFor="Match Result">Match Result: &nbsp;</CFormLabel>
                    {fixtureDetails?.potm_match_status === 1 ? (
                      <span>Won</span>
                    ) : (
                      <span>Other</span>
                    )}
                  </CCol>
                )}
                {fixtureDetails?.fall_of_wicket && (
                  <CCol md={4}>
                    <CFormLabel htmlFor="Entry Fee Info">Fall of Wickets: &nbsp;</CFormLabel>
                    {fixtureDetails?.fall_of_wickets}
                  </CCol>
                )}
                {fixtureDetails?.match_report && (
                  <CCol md={4}>
                    <CFormLabel htmlFor="Add Match Report">Match Report: &nbsp;</CFormLabel>
                    {fixtureDetails?.match_report}
                  </CCol>
                )}
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default ShowScorecard
